import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./styles.module.css";

const AlbumIndex = ({ albums }) => {
    return (
        <div className={styles.albumList}>
            {albums.map((album) => {
                const { mainImage, mainImageCaption, albumPath } = album;
                const [albumName] = albumPath.split("/")[1].split("-");
                return (
                    <Link
                        to={albumPath.split("/")[1]}
                        className={styles.albumLink}
                        key={albumPath}
                    >
                        <GatsbyImage
                            image={getImage(mainImage)}
                            alt={mainImageCaption}
                            className={styles.titleImage}
                        />
                        <h1 className={styles.title}>{albumName} Photos</h1>
                    </Link>
                );
            })}
        </div>
    );
};

AlbumIndex.propTypes = {
    albums: PropTypes.arrayOf(
        PropTypes.shape({
            mainImage: PropTypes.object.isRequired,
            mainImageCaption: PropTypes.string.isRequired,
            albumPath: PropTypes.string.isRequired,
        })
    ),
};

export default AlbumIndex;

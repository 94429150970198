import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import AlbumIndex from "../components/AlbumIndex";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const MobileMargin = styled.div`
    @media (max-width: 1000px) {
        margin: 0 1rem;
    }
`;

const PhotosPage = ({ data }) => {
    const albums = data.allAlbumInfo.edges;

    const albumProcessed = albums.map((album) => {
        const { mainImage, images } = album.node;
        const mainImageCaption = images.find(
            (image) => image.imageName.name === mainImage.name
        ).caption;

        return {
            mainImage,
            mainImageCaption,
            albumPath: mainImage.relativeDirectory,
        };
    });

    return (
        <Layout>
            <Helmet>
                <title>Photos</title>
            </Helmet>
            <MobileMargin>
                <AlbumIndex albums={albumProcessed} />
            </MobileMargin>
        </Layout>
    );
};

export default PhotosPage;

export const query = graphql`
    query {
        allAlbumInfo {
            edges {
                node {
                    mainImage {
                        relativeDirectory
                        name
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    images {
                        caption
                        imageName {
                            name
                        }
                    }
                }
            }
        }
    }
`;
